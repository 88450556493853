import Utils from "./Utils";

export default class Urls
{

    API_BASE = "https://api.recruitment.biozing.com/t17/";

    constructor()
    {
        if ( Utils.DevMode() )
        {
            this.API_BASE =  "https://local.telezing.com/f/zing/recruitment/api/public/t17/";
        }
    }

    LoginUrl = () => {

        return this.API_BASE + "user/login"

    }

    LoadSubmissionUrl = () => {
        return this.API_BASE + "user/submission/"
    }


    VerifyEmailUrl = () => {

        return this.API_BASE + "user/verify"
    }

    ResendVerificationCodeUrl = () => {

        return this.API_BASE + "user/resend"
    }

    CreateAccountUrl = () => {

        return this.API_BASE + "account"

    }

    UpdateUserUrl = () => {

        return this.API_BASE + "user"

    }

    ApplicationDisplayUrl = () => {
        // add application link to create url for candidate to show application
        // must be full url suitable for posting to web sites, emails, etc
        if ( Utils.DevMode() )
        {
           return "http://localhost:3002/";
        }
        else
        {
            return  this.getWebBase() + "job/";
        }
    }

    ViewSubmissionUrl = () => {
        // add submission id to create url to view submission
        if ( Utils.DevMode() )
        {
            return "http://localhost:3003/submission/";
           //return "https://recruitment.biozing.com/submission/";
        }
        else
        {
            return this.getWebBase() + "submission/";
        }
    }

    getWebBase = () => {

        let host = window.location.hostname;
        if ( host === "local.telezing.com" )
        {
            return "https://local.telezing.com/recruitment/";
        }

        if ( host === "staging.recruitment.biozing.com" )
        {
            if ( window.location.href.indexOf("https://staging.recruitment.biozing.com/ubs/" ) === 0 )
            {
                return "https://staging.recruitment.biozing.com/ubs/";
            }
            return "https://staging.recruitment.biozing.com/";
        }

        if ( host === "recruitment.denariusmedia.com.au" )
        {
            return "https://recruitment.denariusmedia.com.au/";
        }

        if ( host === "apacmc.biozing.com" )
        {
            return "https://apacmc.biozing.com/";
        }

        if ( host === "recruiter.biozing.com" )
        {
            return "https://recruiter.biozing.com/";
        }

        return "https://recruitment.biozing.com/";
    }

    UploadDocumentUrl = () =>
    {
        return this.API_BASE + "upload/document";
    }

    UploadImageUrl = () =>
    {
        return this.API_BASE + "upload/image";
    }


    UploadVideoUrl = () =>
    {
        return this.API_BASE + "upload/video";
    }

    ImageSeachUrl = () =>
    {
        return this.API_BASE + "search/image";
    }

    UserTokenUrl = () =>
    {
        return this.API_BASE + "user/token";
    }


    SaveApplicationUrl = () =>
    {
        // add id of application to update
        return this.API_BASE + "user/application/";
    }

    PostApplicationUrl = () =>
    {
        // add id of application to post
        return this.API_BASE + "user/application/";
    }

    ListApplicationsUrl = () => {
        return this.API_BASE + "user/applications";
    }

    ListShortlistedSubmissionsUrl = () => {
        return this.API_BASE + "user/shortlisted";
    }

    ListRecentSubmissionsUrl = () => {
        return this.API_BASE + "user/recent/submissions";
    }

    LoadShortlistedSubmissionUrl = () => {
        // add id of submission
        return this.API_BASE + "user/shortlist/";
    }

    ListApplicationsSummaryUrl = () => {
        return this.API_BASE + "user/applications/summary";
    }

    LoadApplicationUrl = () => {
        // add id of application to load
        return this.API_BASE + "user/application/";
    }

    ListSubmissionsUrl = () => {
        // add link of position to get submission for
        return this.API_BASE + "user/submissions/";
    }

    DeleteSubmissionUrl = () => {
        // add submission id, send as DELETE
        return this.API_BASE + "user/submission/"
    }

    ArchiveSubmissionUrl = () => {
        // add submission id, send as DELETE, include archive=1 in args
        return this.API_BASE + "user/submission/"
    }

    LoadPublicApplicationUrl = () => {
        // add id of application to load
        return this.API_BASE + "public/application/";
    }

    DeleteSubmissionNotificationsUrl = () => {
        return this.API_BASE + "user/notification/"
    }


    ClearSubmissionNotificationsUrl = () => {
        return this.API_BASE + "user/notifications"
    }


    UserInitRecoverAccountUrl = () => {
        return this.API_BASE + "user/recover"
    }

    AccountResetPasswordUrl = () => {
        return this.API_BASE + "account/recover"
    }

    UserUpdatePushNotificationsUrl = () => {
        return this.API_BASE + "user/push"
    }

    UserSavePushTokenUrl = () => {
        return this.API_BASE + "user/push/tokens"
    }

    CandidatesListUrl = () => {
        // GET
        // lists active candidates
        return this.API_BASE + "user/candidates"
    }

    ClientsListUrl = () => {
        // GET
        // lists all active clients
        // add ?inactive=1 to list inactive clients.
        return this.API_BASE + "user/clients"
    }

    CreateClientUrl = () => {
        // POST
        // only name field is mandatory
        return this.API_BASE + "user/client"
    }

    UpdateClientUrl = () => {
        // PUT
        // add client_id eg: user/client/23
        // pass fields to be updated eg: { "primary_contact": "Joe Bow", "primary_phone": "44552223"}
        return this.API_BASE + "user/client"
    }

    CandidateTagsUrl = () => {
        return this.API_BASE + "user/tags/candidate"
    }

    SaveCandidateTagsUrl = () => {
        return this.API_BASE + "user/tags/candidate"
    }


    SubmissionTagsUrl = () => {
        // add submission id
        return this.API_BASE + "user/tags/submission/"
    }

    ListClientSubmissionsUrl = () => {
        // GET
        // lists active submssions for client
        // pass { client_id: id } for client
        return this.API_BASE + "user/client/submissions"
    }

    CopySubmissionToNewPositionUrl = () => {
        // add id of submission to copy eg: /user/copy/submission/230
        // include { position_id: id } of new position to copy to
        return this.API_BASE + "user/copy/submission/"
    }
}
